const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 54 56"
      fill="none"
    >
      <mask
        id="mask0_61_397"
        style={{ "mask-type": "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="51"
        height="52"
      >
        <path
          d="M52.4166 23.3333H28.5V33.2499H42.2666C40.9833 39.5499 35.6166 43.1666 28.5 43.1666C20.1 43.1666 13.3333 36.3999 13.3333 27.9999C13.3333 19.5999 20.1 12.8333 28.5 12.8333C32.1166 12.8333 35.3833 14.1166 37.95 16.2166L45.4166 8.74992C40.8666 4.78325 35.0333 2.33325 28.5 2.33325C14.2666 2.33325 2.83331 13.7666 2.83331 27.9999C2.83331 42.2333 14.2666 53.6666 28.5 53.6666C41.3333 53.6666 53 44.3333 53 27.9999C53 26.4833 52.7666 24.8499 52.4166 23.3333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_61_397)">
        <path
          d="M0.5 43.1666V12.8333L20.3333 27.9999L0.5 43.1666Z"
          fill="#FBBC05"
        />
      </g>
      <mask
        id="mask1_61_397"
        style={{ "mask-type": "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="51"
        height="52"
      >
        <path
          d="M52.4166 23.3333H28.5V33.2499H42.2666C40.9833 39.5499 35.6166 43.1666 28.5 43.1666C20.1 43.1666 13.3333 36.3999 13.3333 27.9999C13.3333 19.5999 20.1 12.8333 28.5 12.8333C32.1166 12.8333 35.3833 14.1166 37.95 16.2166L45.4166 8.74992C40.8666 4.78325 35.0333 2.33325 28.5 2.33325C14.2666 2.33325 2.83331 13.7666 2.83331 27.9999C2.83331 42.2333 14.2666 53.6666 28.5 53.6666C41.3333 53.6666 53 44.3333 53 27.9999C53 26.4833 52.7666 24.8499 52.4166 23.3333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_61_397)">
        <path
          d="M0.5 12.8333L20.3333 28L28.5 20.8833L56.5 16.3333V0H0.5V12.8333Z"
          fill="#EA4335"
        />
      </g>
      <mask
        id="mask2_61_397"
        style={{ "mask-type": "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="51"
        height="52"
      >
        <path
          d="M52.4166 23.3333H28.5V33.2499H42.2666C40.9833 39.5499 35.6166 43.1666 28.5 43.1666C20.1 43.1666 13.3333 36.3999 13.3333 27.9999C13.3333 19.5999 20.1 12.8333 28.5 12.8333C32.1166 12.8333 35.3833 14.1166 37.95 16.2166L45.4166 8.74992C40.8666 4.78325 35.0333 2.33325 28.5 2.33325C14.2666 2.33325 2.83331 13.7666 2.83331 27.9999C2.83331 42.2333 14.2666 53.6666 28.5 53.6666C41.3333 53.6666 53 44.3333 53 27.9999C53 26.4833 52.7666 24.8499 52.4166 23.3333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_61_397)">
        <path
          d="M0.5 43.1667L35.5 16.3333L44.7167 17.5L56.5 0V56H0.5V43.1667Z"
          fill="#34A853"
        />
      </g>
      <mask
        id="mask3_61_397"
        style={{ "mask-type": "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="51"
        height="52"
      >
        <path
          d="M52.4166 23.3333H28.5V33.2499H42.2666C40.9833 39.5499 35.6166 43.1666 28.5 43.1666C20.1 43.1666 13.3333 36.3999 13.3333 27.9999C13.3333 19.5999 20.1 12.8333 28.5 12.8333C32.1166 12.8333 35.3833 14.1166 37.95 16.2166L45.4166 8.74992C40.8666 4.78325 35.0333 2.33325 28.5 2.33325C14.2666 2.33325 2.83331 13.7666 2.83331 27.9999C2.83331 42.2333 14.2666 53.6666 28.5 53.6666C41.3333 53.6666 53 44.3333 53 27.9999C53 26.4833 52.7666 24.8499 52.4166 23.3333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_61_397)">
        <path
          d="M56.5 55.9999L20.3333 27.9999L15.6666 24.4999L56.5 12.8333V55.9999Z"
          fill="#4285F4"
        />
      </g>
    </svg>
  );
};
export default GoogleIcon;
