import React from "react";

const LeisureIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
    >
      <path
        d="M5.83334 17.4997C7.58334 18.958 9.33334 20.4163 13.125 20.4163C20.4167 20.4163 20.4167 14.583 27.7083 14.583C31.5 14.583 33.25 16.0413 35 17.4997C36.75 18.958 38.5 20.4163 42.2917 20.4163C49.5833 20.4163 49.5833 14.583 56.875 14.583C60.6667 14.583 62.4167 16.0413 64.1667 17.4997M5.83334 52.4997C7.58334 53.958 9.33334 55.4163 13.125 55.4163C20.4167 55.4163 20.4167 49.583 27.7083 49.583C31.5 49.583 33.25 51.0413 35 52.4997C36.75 53.958 38.5 55.4163 42.2917 55.4163C49.5833 55.4163 49.5833 49.583 56.875 49.583C60.6667 49.583 62.4167 51.0413 64.1667 52.4997M5.83334 34.9997C7.58334 36.458 9.33334 37.9163 13.125 37.9163C20.4167 37.9163 20.4167 32.083 27.7083 32.083C31.5 32.083 33.25 33.5413 35 34.9997C36.75 36.458 38.5 37.9163 42.2917 37.9163C49.5833 37.9163 49.5833 32.083 56.875 32.083C60.6667 32.083 62.4167 33.5413 64.1667 34.9997"
        stroke="#1B1B1B"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LeisureIcon;
